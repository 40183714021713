@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url("./fonts/Inter-Regular.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    font-weight: 500;
    src: url("./fonts/Inter-Medium.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    font-weight: 600;
    src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url("./fonts/Inter-Bold.ttf") format("truetype");
    font-display: swap;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.bg-external-job {
  background: linear-gradient(90deg, #4f8be9 0%, #6fc1f1 100%);
  @apply max-md:bg-none;
}

#distance-slider > div:last-child > div {
  top: 23px !important;
  width: 36px !important;
  height: 80px !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  background-image: url(/public/custom-thumb.png);
  background-repeat: no-repeat !important;
}

.input-container label {
  transform: translate(0, 15px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container:focus-within label {
  transform: translate(0, 3px) scale(0.7);
  padding-left: 12px;
  z-index: 100;
}

.input-container .filled {
  transform: translate(0, 3px) scale(0.7);
  padding-left: 11.5px;
  z-index: 100;
  border: 1px transparent solid;
}

.shadow--left {
  left: -2px;
  /* background: linear-gradient(90deg, rgba(0, 0, 0, 0.35), transparent); */
  background: rgba(0, 0, 0, 0.7);
  filter: blur(7px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  opacity: 0;
  height: 22px;
  top: 11px;
}

.shadow--right {
  right: -2px;
  /* background: linear-gradient(270deg, rgba(0, 0, 0, 0.35), transparent); */
  background: rgba(0, 0, 0, 0.7);
  filter: blur(7px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 22px;
  top: 11px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.file-input-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.file-input-container input[type="file"] {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 70px;
  height: 70px;
  position: relative;
}

.loader:before {
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 6px solid #007bff;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulse 1s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content {
  display: none;
}

.loaded .loader-container {
  display: none;
}

.loaded .content {
  display: block;
}

/* Scrollbar.css */

/* Target the scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
   height: 5px;
}

/* The scrollbar track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded corners */
}

/* The draggable scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

/* The draggable scrollbar thumb when being hovered */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}
